import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Polishing",
  "description": null,
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Powder-Coating", "Embossing-Debossing"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`NexPCB offers polishing processes for metals too. The process could be done by mechanical, chemical, or electrochemical processes. Since polishing is done manually, there’s no limitation to the size of the workpiece to be polished. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      